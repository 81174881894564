import Siema from 'siema';

window.ProjectModal = () => {
    return {
        loading: true,
        opened: false,
        project: null,
        slider: null,
        activeIndex : 0,
        open({url})
        {
            this.get(url);
        },
        get(url)
        {
            this.loading = true;
            this.opened = true;

            const elements = document.getElementsByClassName('toDelete')

            Array.from(elements).forEach(function(element) {
                element.parentNode.removeChild(element);
            });
            this.activeIndex = 0;
            fetch(url, {
                method: 'GET',
                headers: {
                    'content-type': 'application/json;charset=UTF-8',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            })
                .then((response) => response.json())
                .then(response => {
                    this.project = response.project;
                    this.loading = false;
                    
                });
        },
        
        scrollToIndex(index)
        {
            console.log("scroll to" + index);
            this.activeIndex = index;
            document.getElementById("media" + index).scrollIntoView();
        },
        close()
        {
            this.opened = false;
            this.project = null;
            this.loading = true;
        }
    }

}